import React, { useState, useEffect } from "react";
import {
  FaShoppingCart,
  FaGraduationCap,
  FaHome,
  FaSignOutAlt,
  FaSearch,
  FaUser,
  FaClipboardList,
  FaUniversity,
} from "react-icons/fa";
import BottomNav from "./bottomNav";
import {
  Button,
  Container,
  Form,
  Row,
  Col,
  Card,
  Table,
  Collapse,
} from "react-bootstrap";
import { database } from "../firebase/firebase"; // Adjust the path as necessary
import { ref, onValue, set, get } from "firebase/database";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/search.css";
import two from "../images/two.webp";
import Header from "./upperNav";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import { useCart } from "../contexts/cartContext"; // Import useCart
import Papa from "papaparse";

const SchoolSearch = () => {
  const [randomSchools, setRandomSchools] = useState([]); // New state for random schools
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [requiredBooks, setRequiredBooks] = useState([]);
  const [allSchools, setAllSchools] = useState({});
  const [showBooks, setShowBooks] = useState(false);
  const [showGrades, setShowGrades] = useState(true);
  const [removedBooks, setRemovedBooks] = useState(new Set());
  const { addToCart } = useCart();
  const { currentUser } = useAuth();
  const [suggestedSchools, setSuggestedSchools] = useState([]);

  useEffect(() => {
    const filteredResults = Object.keys(allSchools).filter((schoolName) =>
      schoolName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setResults(filteredResults);
  }, [searchTerm, allSchools]);

  useEffect(() => {
    const schoolsRef = ref(database, "schools/");
    onValue(schoolsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setAllSchools(data);
        // Select three random schools when data is loaded
        const schoolNames = Object.keys(data);
        const selectedRandomSchools = [];
        while (
          selectedRandomSchools.length < 3 &&
          selectedRandomSchools.length < schoolNames.length
        ) {
          const randomIndex = Math.floor(Math.random() * schoolNames.length);
          const schoolName = schoolNames[randomIndex];
          if (!selectedRandomSchools.includes(schoolName)) {
            selectedRandomSchools.push(schoolName);
          }
        }
        setRandomSchools(selectedRandomSchools);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedSchool && selectedGrade) {
      const gradeMapping = {
        0: "PP1",
        1: "PP2",
        2: "Grade 1",
        3: "Grade 2",
        4: "Grade 3",
        5: "Grade 4",
        6: "Grade 5",
        7: "Grade 6",
        8: "Grade 7",
        9: "Grade 8",
        10: "Grade 9",
      };
      const mappedGrade = gradeMapping[selectedGrade] || selectedGrade; // Use mapping if available

      console.log("Fetching data for School:", selectedSchool.name);
      console.log("Fetching data for Grade:", mappedGrade); // Log mapped grade

      const booksRef = ref(
        database,
        `schools/${selectedSchool.name}/grades/${mappedGrade}/books/`
      );

      onValue(booksRef, (snapshot) => {
        const books = snapshot.val();
        console.log("Raw Books Data:", books); // Log raw data

        if (books) {
          const formattedBooks = Object.values(books).map((book) => ({
            name: book.name,
            price: book.price,
            imageUrl: book.imageUrl || "",
            type: book.type || "other",
          }));

          console.log("Formatted Books:", formattedBooks);

          const groupedBooks = {};
          formattedBooks.forEach((book) => {
            if (!groupedBooks[book.type]) {
              groupedBooks[book.type] = [];
            }
            groupedBooks[book.type].push(book);
          });

          const categoryOrder = ["Compulsory", "Stationery", "Complementary"];
          const sortedCategories = categoryOrder.filter((category) =>
            groupedBooks.hasOwnProperty(category)
          );

          setRequiredBooks(
            sortedCategories.map((category) => ({
              category,
              books: groupedBooks[category],
            }))
          );
        } else {
          setRequiredBooks([]); // No books found
        }
      });
    } else {
      setRequiredBooks([]); // Handle case when no school or grade selected
    }
  }, [selectedSchool, selectedGrade]);

  const handleSchoolSelect = (schoolName) => {
    const schoolData = allSchools[schoolName];
    setSelectedSchool({ name: schoolName, ...schoolData });
    setSelectedGrade(null);
    setRequiredBooks([]);
    setRemovedBooks(new Set());
    setShowBooks(false);
    setShowGrades(true);
  };

  const handleGradeSelect = (grade) => {
    const gradeMapping = {
      "PP1": "PP1",
      "PP2": "PP2",
      "1": "Grade 1",
      "2": "Grade 2",
      "3": "Grade 3",
      "4": "Grade 4",
      "5": "Grade 5",
      "6": "Grade 6",
      "7": "Grade 7",
      "8": "Grade 8",
      "9": "Grade 9",
    };
    const mappedGrade = gradeMapping[grade]; // Map the grade value correctly
    setSelectedGrade(mappedGrade); // Set the correct grade value
    setShowBooks(true);
    setShowGrades(false);
  };
  

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setSelectedSchool(null);
    setSelectedGrade(null);
    setRequiredBooks([]);
    setRemovedBooks(new Set());
    setShowBooks(false);
    setShowGrades(true);
  };

  const [showLoginSignup] = useState(false);

  const handleAddToCart = () => {
    // Generate books to add with a default quantity of 1 if not already set
    const booksToAdd = requiredBooks.reduce((acc, category) => {
      return [
        ...acc,
        ...category.books
          .filter((book) => !removedBooks.has(book.name))
          .map((book) => ({
            ...book,
            school: selectedSchool.name,
            grade: selectedGrade,
            quantity: book.quantity || 1, // Ensure quantity defaults to 1
          })),
      ];
    }, []);

    // Check if the user is logged in
    if (currentUser) {
      const cartRef = ref(database, `users/${currentUser.uid}/cart`);

      // Use a single read and update operation to avoid loops
      get(cartRef)
        .then((snapshot) => {
          const existingCart = snapshot.val() || [];

          // Update the cart by merging existing items and new items
          const updatedCart = booksToAdd.reduce((acc, newBook) => {
            const existingItemIndex = acc.findIndex(
              (item) =>
                item.name === newBook.name &&
                item.school === newBook.school &&
                item.grade === newBook.grade
            );

            if (existingItemIndex !== -1) {
              // If the book is already in the cart, update the quantity
              acc[existingItemIndex].quantity += newBook.quantity;
            } else {
              // Otherwise, add the new book with a quantity of 1
              acc.push(newBook);
            }

            return acc;
          }, existingCart);

          // Update the cart in Firebase
          set(cartRef, updatedCart)
            .then(() => {
              addToCart(updatedCart); // Update the cart in local state
              alert("Books added to cart successfully.");
              navigate("/cart");
            })
            .catch((error) => {
              console.error("Error updating cart:", error);
              alert("Failed to add books to cart.");
            });
        })
        .catch((error) => {
          console.error("Error fetching cart:", error);
        });
    } else {
      alert("Please log in to save your cart.");
    }
  };
  const handleAddTypeToCart = (type) => {
    // Generate books to add with a default quantity of 1 if not already set
    const booksToAdd =
      requiredBooks
        .find((category) => category.category === type)
        ?.books.filter((book) => !removedBooks.has(book.name))
        .map((book) => ({
          ...book,
          school: selectedSchool.name,
          grade: selectedGrade,
          quantity: book.quantity || 1, // Ensure quantity defaults to 1
        })) || [];

    // Check if the user is logged in
    if (currentUser) {
      const cartRef = ref(database, `users/${currentUser.uid}/cart`);

      // Use a single read and update operation to avoid loops
      get(cartRef)
        .then((snapshot) => {
          const existingCart = snapshot.val() || [];

          // Update the cart by merging existing items and new items
          const updatedCart = booksToAdd.reduce((acc, newBook) => {
            const existingItemIndex = acc.findIndex(
              (item) =>
                item.name === newBook.name &&
                item.school === newBook.school &&
                item.grade === newBook.grade
            );

            if (existingItemIndex !== -1) {
              // If the book is already in the cart, update the quantity
              acc[existingItemIndex].quantity += newBook.quantity;
            } else {
              // Otherwise, add the new book with a quantity of 1
              acc.push(newBook);
            }

            return acc;
          }, existingCart);

          // Update the cart in Firebase
          set(cartRef, updatedCart)
            .then(() => {
              addToCart(updatedCart); // Update the cart in local state
              alert(`${type} books added to cart successfully.`);
              navigate("/cart");
            })
            .catch((error) => {
              console.error("Error updating cart:", error);
              alert("Failed to add books to cart.");
            });
        })
        .catch((error) => {
          console.error("Error fetching cart:", error);
        });
    } else {
      alert("Please log in to save your cart.");
    }
  };

  const totalCost = requiredBooks.reduce((total, category) => {
    return (
      total +
      category.books
        .filter((book) => !removedBooks.has(book.name))
        .reduce((catTotal, book) => catTotal + book.price, 0)
    );
  }, 0);
  const handleAddBookToCart = (book) => {
    if (currentUser) {
      const cartRef = ref(database, `users/${currentUser.uid}/cart`);

      get(cartRef)
        .then((snapshot) => {
          const existingCart = snapshot.val() || [];

          // Check if the book is already in the cart
          const existingItemIndex = existingCart.findIndex(
            (item) =>
              item.name === book.name &&
              item.school === selectedSchool.name &&
              item.grade === selectedGrade
          );

          // Update the cart
          if (existingItemIndex !== -1) {
            existingCart[existingItemIndex].quantity += 1; // Increment quantity if the book is already in the cart
          } else {
            existingCart.push({
              ...book,
              school: selectedSchool.name,
              grade: selectedGrade,
              quantity: 1, // Add book with a quantity of 1
            });
          }

          // Update the cart in Firebase
          set(cartRef, existingCart)
            .then(() => {
              addToCart(existingCart); // Update the cart in local state
              alert(`${book.name} added to cart successfully.`);
            })
            .catch((error) => {
              console.error("Error updating cart:", error);
              alert("Failed to add book to cart.");
            });
        })
        .catch((error) => {
          console.error("Error fetching cart:", error);
        });
    } else {
      alert("Please log in to add books to your cart.");
    }
  };

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <Container>
      <Header handleLogout={handleLogout} />
      <h1 className="text-center my-4">Find Your Child's School</h1>
      <div id="search-section">
        <Form
          onSubmit={(e) => e.preventDefault()}
          className="mb-2 d-flex justify-content-center align-items-center" // Adjust the margin-bottom here
        >
          <Row className="align-items-center">
            <Col xs="auto">
              <Form.Control
                type="text"
                placeholder="Enter school name"
                value={searchTerm}
                onChange={handleSearchChange}
                className="custom-search-input"
              />
            </Col>
            <Col>
              <Button variant="primary" className="custom-search-button">
                Search School
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      {selectedSchool ? (
        <div className="mt-3">
          <h3 className="text-center">
            Selected School: <span className="h2">{selectedSchool.name}</span>
          </h3>

          <div className="mt-2">
            <Collapse in={showGrades}>
              <div className="grades-container">
                <h4 className="text-center mb-4">Choose Your Grade:</h4>
                <Row className="justify-content-center">
                  {[
                    "PP1",
                    "PP2",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                  ].map((grade, index) => (
                    <Col xs={6} sm={4} md={3} key={index} className="mb-2">
                      <Button
                        variant={
                          selectedGrade === grade
                            ? "primary"
                            : "outline-primary"
                        }
                        onClick={() => handleGradeSelect(grade)}
                        className={`w-100 grade-button ${grade}-bg`}
                      >
                        <FaGraduationCap className="school-icon mb-2" />
                        {`Grade ${grade}`}
                      </Button>
                    </Col>
                  ))}
                </Row>
              </div>
            </Collapse>

            <Collapse in={showBooks}>
              <div className="mt-3">
                <h4 className="text-center">
                  {selectedGrade
                    ? `Books for Grade ${selectedGrade}`
                    : "Required Books"}
                </h4>

                {requiredBooks.length > 0 ? (
                  <>
                    {requiredBooks.map((category, index) => (
                      <div key={index}>
                        {/* If category is 'Stationery', 'Complementary', or 'Compulsory', use a table */}
                        {category.category === "Stationery" ? (
                          <>
                            <h2>Stationery</h2>
                            <strong>
                              <p>Adjust quantities at the cart.</p>
                            </strong>
                            <Button
                              variant="primary"
                              className="custom-add-button"
                              onClick={() =>
                                handleAddTypeToCart(category.category)
                              }
                            >
                              Add All {category.category} & Books to Cart
                            </Button>
                            <Table
                              responsive
                              striped
                              bordered
                              hover
                              className="mt-2"
                            >
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Item</th>
                                  <th>Price (KES)</th>
                                  <th>Add to Cart</th>
                                </tr>
                              </thead>
                              <tbody>
                                {category.books.map((book, bookIndex) => (
                                  <tr key={bookIndex}>
                                    <td>
                                      <img
                                        src={book.imageUrl || two}
                                        alt={two}
                                        className="table-book-image"
                                      />
                                    </td>

                                    <td>{book.name}</td>
                                    <td>KES {book.price.toFixed(2)}</td>
                                    <td>
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          handleAddBookToCart(book)
                                        }
                                        className="mt-2"
                                      >
                                        <FaShoppingCart className="me-2" /> Add
                                        to Cart
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        ) : category.category === "Complementary" ? (
                          <>
                            <h1>Readers List</h1>
                            <strong>
                              <p>
                                Choose one Kiswahili reader and one English
                                reader.
                              </p>
                            </strong>

                            <Table
                              responsive
                              striped
                              bordered
                              hover
                              className="mt-2"
                            >
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Item</th>
                                  <th>Price (KES)</th>
                                  <th>Add to Cart</th>
                                </tr>
                              </thead>
                              <tbody>
                                {category.books.map((book, bookIndex) => (
                                  <tr key={bookIndex}>
                                    <td>
                                      {book.imageUrl && (
                                        <img
                                          src={book.imageUrl}
                                          alt={two}
                                          className="table-book-image"
                                        />
                                      )}
                                    </td>
                                    <td>{book.name}</td>
                                    <td>KES {book.price.toFixed(2)}</td>
                                    <td>
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          handleAddBookToCart(book)
                                        }
                                        className=" exciting-button"
                                      >
                                        <FaShoppingCart className="me-2" /> Add
                                        to Cart
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        ) : category.category === "Compulsory" ? (
                          <>
                            <h2>Course Books</h2>
                            <Button
                              variant="primary"
                              className="custom-add-button"
                              onClick={() =>
                                handleAddTypeToCart(category.category)
                              }
                            >
                              Add All {category.category} Books to Cart
                            </Button>
                            <Table
                              responsive
                              striped
                              bordered
                              hover
                              className="mt-2"
                            >
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Item</th>
                                  <th>Price (KES)</th>
                                  <th>Add to Cart</th>
                                </tr>
                              </thead>
                              <tbody>
                                {category.books.map((book, bookIndex) => (
                                  <tr key={bookIndex}>
                                    <td>
                                      {book.imageUrl && (
                                        <img
                                        src={book.imageUrl ? book.imageUrl : two}
                                        
                                        className="table-book-image"
                                      />
                                      
                                      )}
                                    </td>
                                    <td>{book.name}</td>
                                    <td>KES {book.price.toFixed(2)}</td>
                                    <td>
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          handleAddBookToCart(book)
                                        }
                                        className="mt-2"
                                      >
                                        <FaShoppingCart className="me-2" /> Add
                                        to Cart
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        ) : (
                          // For other categories
                          <div className="mt-2">
                            <p>
                              {category.category} books are not displayed in a
                              table format.
                            </p>
                            {/* Render other category content here, e.g., a list of books */}
                            <ul>
                              {category.books.map((book, bookIndex) => (
                                <li key={bookIndex}>
                                  {book.name} - KES {book.price.toFixed(2)}
                                  <Button
                                    variant="primary"
                                    onClick={() => handleAddBookToCart(book)}
                                    className="mt-2 ms-2"
                                  >
                                    <FaShoppingCart className="me-2" /> Add to
                                    Cart
                                  </Button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-center">Currently not available.</p>
                )}
              </div>
            </Collapse>
          </div>
        </div>
      ) : (
        <>
          {searchTerm ? (
            <>
              <h2 className="text-center my-2">Search Results</h2>
              <div className="common-schools">
                {results.length > 0 ? (
                  results.map((schoolName, index) => (
                    <div
                      className="school-item"
                      key={index}
                      onClick={() => handleSchoolSelect(schoolName)}
                    >
                      <FaUniversity className="school-icon mb-2" />
                      <Card.Title className="school-title">
                        {schoolName}
                      </Card.Title>
                      <Card.Text className="school-location">
                        {allSchools[schoolName].location}
                      </Card.Text>
                    </div>
                  ))
                ) : (
                  <p className="text-center">No matching schools found.</p>
                )}
              </div>
            </>
          ) : (
            <>
              <h2 className="text-center my-4">Suggested Schools</h2>
              <div className="common-schools">
                {randomSchools.map((schoolName, index) => (
                  <div
                    className="school-item"
                    key={index}
                    onClick={() => handleSchoolSelect(schoolName)}
                  >
                    <FaUniversity className="school-icon mb-2" />
                    <Card.Title className="school-title">
                      {schoolName}
                    </Card.Title>
                    <Card.Text className="school-location">
                      {allSchools[schoolName].location}
                    </Card.Text>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
      <footer className="bg-light text-center py-3">
        <div className="container">
          <p className="mb-0">
            &copy; 2024 Booklist Shop. All rights reserved.
          </p>
        </div>
      </footer>

      <BottomNav handleLogout={handleLogout} />
    </Container>
  );
};
export default SchoolSearch;
