import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import Login from "./components/login";
import SignUp from "./components/signup";
import ResetPassword from "./components/resetPassword";
import Home from "./components/home";
import AboutUs from "./components/aboutUs";
import OrderDetails from "./components/account/orderDetails";
import Orders from "./components/account/orders";
import SchoolSearch from "./components/searchSchool";
import { AuthProvider } from "./contexts/authContext";

import Cart from "./components/cart";
import { CartProvider } from "./contexts/cartContext";
import Checkout from "./components/checkout";
import whatPng from './images/file.png';
import PrivateRoute from './privateRoute'; // Import the PrivateRoute component
import AdminDashboard from "./Admin/adminNavigation";
import AdminAdd from "./Admin/AdminAdd";
import AdminAddBook from "./Admin/AdminAddBook";
import AdminAddStationery from "./Admin/AdminAddStationery";
import OrdersPerSchool from './Admin/OrdersPerSchool';
import CreateBooklist from "./components/createBooklist";

function App() {
  const location = useLocation();
  const [showLoginSignup, setShowLoginSignup] = useState(false);

  const showNavbar =
    location.pathname === "/sign-in" ||
    location.pathname === "/about-us" ||
    location.pathname === "/reset-password" ||
    location.pathname === "/sign-up" ||
    location.pathname === "/";

  const toggleLoginSignup = () => {
    setShowLoginSignup(!showLoginSignup);
  };

  return (
    <div className="App">
      {showNavbar && (
        <nav className="navbar navbar-expand-lg navbar-light " style={{ backgroundColor: 'silver'}}>
          <div className="container" >
            <Link className="navbar-brand me-auto" to="/">
              <img src={whatPng} className="logo" alt="Logo" />
              Skool Booklists
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={toggleLoginSignup}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${
                showLoginSignup ? "show" : ""
              }`}
              id="navbarTogglerDemo02"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item me-3">
                  <Link className="nav-link" to="/sign-in">
                    Login
                  </Link>
                </li>
                <li className="nav-item me-3">
                  <Link className="nav-link" to="/sign-up">
                    Sign up
                  </Link>
                </li>
                <li className="nav-item me-3">
                  <Link className="nav-link" to="/about-us">
                    About Us
                  </Link>
                </li>
                <li className="nav-item me-3">
                  <Link className="nav-link" to="/contact-us">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}

      <div className="auth-wrapper">
        <div className="auth-inner">
          <AuthProvider>
            <CartProvider>
              <Routes>
                <Route path="/" element={<AboutUs />} />
                <Route path="/sign-in" element={<Login />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/home" element={<PrivateRoute element={<Home />} />} />
                <Route path="/create-booklist" element={<PrivateRoute element={<CreateBooklist />} />} />
                <Route path="/school" element={<PrivateRoute element={<SchoolSearch />} />} />
                <Route path="/admin/orders" element={<PrivateRoute element={<OrdersPerSchool />} />} />
                <Route path="/admin" element={<PrivateRoute element={<AdminDashboard />} requiredRole="admin" />} />
                <Route path="/admin/add" element={<PrivateRoute element={<AdminAdd />} requiredRole="admin" />} />
                <Route path="/admin/add-stationery" element={<PrivateRoute element={<AdminAddStationery />} requiredRole="admin" />} />
                <Route path="/admin/add-books" element={<PrivateRoute element={<AdminAddBook />} requiredRole="admin" />} />
                <Route path="/cart" element={<PrivateRoute element={<Cart />} />} />
                <Route path="/checkout" element={<PrivateRoute element={<Checkout />} />} />
                <Route path="/orders" element={<PrivateRoute element={<Orders />} />} />
                <Route path="/order-details/:orderId" element={<PrivateRoute element={<OrderDetails />} />} />
              </Routes>
            </CartProvider>
          </AuthProvider>
        </div>
      </div>
    </div>
  );
}

// Wrap App in Router to use useLocation
function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default WrappedApp;
