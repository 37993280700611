import React, { useState, useEffect } from "react";
import { database } from "../firebase/firebase"; // Adjust the path as necessary
import { ref, onValue, remove, set } from "firebase/database";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import Papa from "papaparse";
import {
  FaHome,
  FaChartBar,
  FaClipboardList,
  FaSignOutAlt,
  FaPlusSquare,
} from "react-icons/fa";
import "./AdminDashboard.css";
import AdminSidebar from "./AdminSidebar";
import {
  getStorage,
  ref as firebaseStorageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { deleteObject } from "firebase/storage";
import two from "../images/two.webp";

const AdminAddBook = () => {
  const [schools, setSchools] = useState([]);
  const [schoolName, setSchoolName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentSchool, setCurrentSchool] = useState("");
  const [currentGrade, setCurrentGrade] = useState("");
  const [bookName, setBookName] = useState("");
  const [bookPrice, setBookPrice] = useState("");
  const [bookImage, setBookImage] = useState(null);
  const [itemType, setItemType] = useState("Compulsory"); // Default to 'Compulsory'
  const [booklist, setBooklist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [quantity, setQuantity] = useState(""); // State for quantity

  const storage = getStorage();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [filteredBooklist, setFilteredBooklist] = useState([]);

  const grades = [
    "PP1",
    "PP2",
    ...Array.from({ length: 9 }, (_, i) => `Grade ${i + 1}`),
  ];

  // Fetch books when grade or itemType is selected
  useEffect(() => {
    if (currentGrade) {
      const bookKey = `Books/grades/${currentGrade}/`;
      onValue(ref(database, bookKey), (snapshot) => {
        const books = snapshot.val() || {};
        const allBooks = Object.entries(books).map(([id, data]) => ({
          id,
          ...data,
        }));

        // Set booklist and filter based on itemType at the same time
        setBooklist(allBooks);
        setFilteredBooklist(allBooks.filter((book) => book.type === itemType));
      });
    }
  }, [currentGrade, itemType]); // Trigger on either currentGrade or itemType change
  // Trigger when grade or itemType changes

  const addBook = async () => {
    if (!currentGrade) {
      console.error("Grade must be selected.");
      return;
    }
    if (!bookName || !bookPrice) {
      console.error("Book name and price must be filled.");
      return;
    }

    const bookKey = `Books/grades/${currentGrade}/`;
    const newBookId = new Date().getTime();
    let newBookEntry = {
      id: newBookId,
      name: bookName,
      price: parseFloat(bookPrice),
      type: itemType,
    };

    if (bookImage) {
      const imageRef = firebaseStorageRef(storage, `books/${newBookId}`);
      try {
        // Upload image
        await uploadBytes(imageRef, bookImage);

        // Get image URL
        const imageUrl = await getDownloadURL(imageRef);
        newBookEntry.imageUrl = imageUrl;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    } else {
      newBookEntry.imageUrl = two; // Assign imageUrl instead of reassigning newBookEntry
    }

    // Save book details to the database
    await set(
      ref(database, `${bookKey}${bookName.replace(/[\s.]/g, "_")}`),
      newBookEntry
    );

    // Clear input fields
    setBookName("");
    setBookPrice("");
    setBookImage(null);
    setItemType("Compulsory"); // Reset to default
  };

  // Update the handleItemTypeChange function to accommodate stationery

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const deleteBook = (bookName, imageUrl) => {
    const bookKey = `Books/grades/${currentGrade}/${bookName.replace(
      /[\s.]/g,
      "_"
    )}`;

    // Delete the book entry from Firebase Database
    remove(ref(database, bookKey))
      .then(() => {
        setBooklist((prevBooks) =>
          prevBooks.filter((book) => book.name !== bookName)
        );
      })
      .catch((error) => {
        console.error("Error deleting book from database:", error);
      });

    // Delete the image from Firebase Storage
    if (imageUrl) {
      const imageRef = firebaseStorageRef(storage, imageUrl);
      deleteObject(imageRef)
        .then(() => {
          console.log("Image successfully deleted.");
        })
        .catch((error) => {
          console.error("Error deleting image from storage:", error);
        });
    }
  };
  const handleBulkUpload = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    Papa.parse(file, {
      header: true, // Assuming the CSV has headers
      complete: (results) => {
        const books = results.data;
        uploadBooks(books); // Pass the parsed books to upload function
      },
      error: (error) => {
        console.error("Error parsing CSV file:", error);
      },
    });
  };

  const uploadBooks = async (books) => {
    if (!currentGrade) {
      console.error("Grade must be selected.");
      return;
    }

    const bookKey = `Books/grades/${currentGrade}/`;

    for (let book of books) {
      const newBookId = new Date().getTime();
      let newBookEntry = {
        id: newBookId,
        name: book.bookName,
        price: parseFloat(book.bookPrice),
        type: itemType || book.itemType, // Default to selected itemType
        imageUrl: book.imageUrl ? book.imageUrl : two, // Fallback to default image `two` if none
      };

      // Save the book to Firebase
      await set(
        ref(database, `${bookKey}${book.bookName.replace(/[\s.]/g, "_")}`),
        newBookEntry
      );
    }
  };

  return (
    <div className="admin-dashboard">
      <AdminSidebar handleLogout={handleLogout} />
      <main className="content">
        <h2 className="text-center">Admin Interface</h2>
      
<div className="mb-4">
  <h3>Single Book Upload</h3>
  <select
    className="form-select mb-2"
    value={currentGrade}
    onChange={(e) => setCurrentGrade(e.target.value)}
  >
    <option value="">Select Grade</option>
    {grades.map((grade, index) => (
      <option key={index} value={grade}>
        {grade}
      </option>
    ))}
  </select>

  <select
    className="form-select mb-2"
    value={itemType}
    onChange={(e) => setItemType(e.target.value)}
  >
    <option value="Compulsory">Compulsory</option>
    <option value="Complementary">Complementary</option>
  </select>

  <input
    type="text"
    className="form-control mb-2"
    value={bookName}
    onChange={(e) => setBookName(e.target.value)}
    placeholder="Book Name"
  />
  <input
    type="number"
    className="form-control mb-2"
    value={bookPrice}
    onChange={(e) => setBookPrice(e.target.value)}
    placeholder="Book Price"
  />
  <p>Upload Book Image</p>
  <input
    type="file"
    className="form-control mb-2"
    accept="image/*"
    onChange={(e) => setBookImage(e.target.files[0])}
  />
  <button className="btn btn-primary" onClick={addBook}>
    Add Book
  </button>
</div>


<div className="bulk-upload mb-4">
  <h3>Bulk Upload Books (CSV)</h3>
  <select
    className="form-select mb-2"
    value={currentGrade}
    onChange={(e) => setCurrentGrade(e.target.value)}
  >
    <option value="">Select Grade</option>
    {grades.map((grade, index) => (
      <option key={index} value={grade}>
        {grade}
      </option>
    ))}
  </select>

  <input
    type="file"
    className="form-control mb-2"
    accept=".csv"
    onChange={handleBulkUpload}
  />
  <button className="btn btn-primary" onClick={handleBulkUpload}>
    Upload CSV
  </button>
</div>


        <h3 className="mt-4">
          Book List for {currentGrade || "Selected Grade"} - {itemType}
        </h3>
        {filteredBooklist.length > 0 ? (
          <ul className="list-group">
            {filteredBooklist.map((book) => (
              <li
                key={book.id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <div>
                  {book.imageUrl && (
                    <img
                      src={book.imageUrl}
                      alt={book.name}
                      style={{ width: "50px", marginRight: "10px" }}
                    />
                  )}
                  <span>
                    <strong>{book.name}</strong> - Kes {book.price} (Type:{" "}
                    {book.type})
                  </span>
                </div>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteBook(book.name, book.imageUrl)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No books available for the selected grade and type.</p>
        )}
      </main>
    </div>
  );
};

export default AdminAddBook;
