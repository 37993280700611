import React, { useState, useEffect } from "react";
import "./Styles/createbooklist.css";
import BottomNav from "./bottomNav";
import Header from "./upperNav";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import { getStorage, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { getDatabase, ref as dbRef, get } from "firebase/database";

const CreateBooklist = () => {
  const [grade, setGrade] = useState("");
  const [option, setOption] = useState("");
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [books, setBooks] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadBooks = async () => {
      const booksData = await fetchBooksForGrade(grade);
      setBooks(booksData || {});
    };

    if (grade) {
      loadBooks();
    }
  }, [grade]);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const storage = getStorage();
    const fileRef = ref(storage, `uploads/${file.name}`);
    setLoading(true);

    try {
      await uploadBytes(fileRef, file);
      console.log("File uploaded successfully!");

      const fileURL = await getDownloadURL(fileRef);
      const response = await fetch(
        "https://us-central1-your-project-id.cloudfunctions.net/api/analyze-image",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ imageUrl: fileURL }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        console.log("Text extracted from image:", data);
      } else {
        console.error("Error analyzing image:", data.error);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file!");
    } finally {
      setLoading(false);
    }
  };

  const fetchBooksForGrade = async (grade) => {
    const db = getDatabase();
    const booksRef = dbRef(db, `books/${grade}`);

    try {
      const snapshot = await get(booksRef);
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.log("No books available for this grade");
        return {};
      }
    } catch (error) {
      console.error("Error fetching books: ", error);
    }
  };

  const handleGradeChange = (e) => {
    setGrade(e.target.value);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleOptionChange = (choice) => {
    setOption(choice);
  };

  return (
    <div className="container">
      <Header handleLogout={handleLogout} />
      <main>
        <h1>Create Your Booklist</h1>

        <section className="grade-selection">
          <h3>Select Grade:</h3>
          <select value={grade} onChange={handleGradeChange}>
            <option value="" disabled>
              Choose Grade
            </option>
            {[...Array(9)].map((_, index) => (
              <option key={index + 1} value={`Grade ${index + 1}`}>
                Grade {index + 1}
              </option>
            ))}
          </select>
        </section>

        {grade && (
          <section className="option-selection">
            <h3>What would you like to do?</h3>
            <div className="buttons">
              <button onClick={() => handleOptionChange("upload")}>
                Upload Booklist
              </button>
              <button onClick={() => handleOptionChange("pick")}>
                Pick Books
              </button>
            </div>
          </section>
        )}

        {option === "upload" && (
          <section className="upload-section">
            <h3>Upload Your Booklist:</h3>
            <input
              type="file"
              accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
              onChange={handleFileUpload}
            />
            {loading && <p>Uploading...</p>}
          </section>
        )}

        {grade && (
          <section className="book-list">
            <h3>Books for {grade}:</h3>
            {Object.keys(books).length > 0 ? (
              <ul>
                {Object.keys(books).map((bookKey) => (
                  <li key={bookKey}>
                    <h4>{books[bookKey].title}</h4>
                    <a
                      href={books[bookKey].url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Book
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No books available for this grade.</p>
            )}
          </section>
        )}

        {option === "pick" && (
          <section className="pick-section">
            <h3>Pick Books:</h3>
            <p>Here you can display the list of books and stationeries.</p>
          </section>
        )}
      </main>
      <BottomNav handleLogout={handleLogout} />
    </div>
  );
};

export default CreateBooklist;
